import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import CryptoJS from "crypto-js";

import { BASENAME, BASE_URL } from "../../config/constant";

const CustomSecurityEncrypt = ({ children }) => {
    const history = useHistory();
    var newurl = '';

    if (window.location.href.includes('?')) {
        if (window.location.href.includes('ChangeService')) {
            var path = window.location.href.split('?')[0];
            path = path.substring(0, path.lastIndexOf('/'));

            var encryptquerystring = window.location.href.split('?')[1].split('ChangeService=')[1];

            var bytes = CryptoJS.AES.decrypt(String(encryptquerystring), process.env.REACT_APP_ENDEVO_SHARE_SECRET_KEY);
            var url_params = bytes.toString(CryptoJS.enc.Utf8);
            var params = queryString.parse(url_params);

            sessionStorage.setItem("nav", "Common");
            sessionStorage.setItem("endevo_change_services", "true");
            sessionStorage.setItem("endevo_userID", params.endevo_userID);
            sessionStorage.setItem("endevo_refreshToken", params.endevo_refreshToken);
            sessionStorage.setItem("endevo_accessToken", params.endevo_accessToken);
            sessionStorage.setItem("endevo_userName", params.endevo_userName);
            sessionStorage.setItem("endevo_profilePic", params.endevo_profilePic);
            sessionStorage.setItem("endevo_LanguageCode", params.endevo_LanguageCode);
            sessionStorage.setItem("endevo_maxTotalFileSize", params.endevo_maxTotalFileSize);
            sessionStorage.setItem("endevo_maxFiles", params.endevo_maxFiles);

            newurl = path + BASENAME + BASE_URL;
            //window.history.replaceState({}, '', newurl);
            window.history.pushState({}, '', newurl);
        }
        //else {
        //    //if (!window.location.href.includes('encrypt')) {
        //    //    var path = window.location.href.split('?')[0];
        //    //    var querystring = window.location.href.split('?')[1];
        //    //    newurl = path + '?encrypt=true&' + CryptoJS.AES.encrypt(querystring, process.env.REACT_APP_SECRET_KEY);
        //    //    window.history.replaceState({}, '', newurl);
        //    //} else {
        //    //    //var encryptquerystring = history.location.search.split('encrypt=true&')[1];
        //    //    //var bytes = CryptoJS.AES.decrypt(String(encryptquerystring), process.env.REACT_APP_SECRET_KEY);
        //    //    ////history.location.search = bytes.toString(CryptoJS.enc.Utf8);
        //    //    //history.replace({
        //    //    //    search: bytes.toString(CryptoJS.enc.Utf8)
        //    //    //})
        //    //}
        //}
    }

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};

export default CustomSecurityEncrypt;
