import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Card, Form, Button, ButtonGroup, Dropdown, DropdownButton, Badge } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import RequireForAccess, { ReactAccessProvider } from 'react-access';

import useAuth from '../../../hooks/useAuth';
import { ConfigContext } from "../../../contexts/ConfigContext";
import LanguageChangeModal from '../../../components/Modal/LanguageChangeModal/LanguageChangeModal';
import AnimatedModal from '../../../components/Modal/AnimatedModal';

import State_Merge from '../../../common/state_merge/state_merge';
import toast from '../../../common/error_handler/Toast'

import { Loading } from '../../../common/loading/custom_loading/loading';

import { userChangePassword_service } from '../../../services/Common/Common';
import Custom_Textbox from '../../../components/Common/Custom_Textbox/Custom_Textbox';

const ChangePasswordModal = props => {
    const configContext = useContext(ConfigContext);
    const { enable_language_change_flag } = configContext.state;

    const { language, languages, user_latitude, user_longitude } = useAuth();
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const [showLoading, setShowLoading] = useState(false);
    const [disableBtnSave, setDisableBtnSave] = useState(false);

    const [userPassword, setUserPassword] = State_Merge({
        txt_user_password: "",
        txt_user_new_password: "",
        txt_user_confirm_new_password: "",
        errors: {
            txt_user_password: "",
            txt_user_new_password: "",
            txt_user_confirm_new_password: ""
        }
    });

    const {
        txt_user_password,
        txt_user_new_password,
        txt_user_confirm_new_password,
        errors
    } = userPassword;

    const handleShowLanguageModal = (lang) => {
        setSelectedLanguage(lang);
        setShowLanguageModal(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setUserPassword({
            [name]: value,
        });

        switch (name) {
            case 'txt_user_password':
                if (value == "") {
                    errors.txt_user_password = "COMMON.This is a required field";
                }
                else {
                    errors.txt_user_password = "";
                }
                break;
            case 'txt_user_new_password':
                if (value == "") {
                    errors.txt_user_new_password = "COMMON.This is a required field";
                }
                else {
                    errors.txt_user_new_password = "";
                }
                break;
            case 'txt_user_confirm_new_password':
                if (value == "") {
                    errors.txt_user_confirm_new_password = "COMMON.This is a required field";
                }
                else {
                    if (txt_user_new_password != txt_user_confirm_new_password) {
                        errors.txt_user_confirm_new_password = "COMMON.New Password And Confirm New Password Must Be Same";
                    }
                    else {
                        errors.txt_user_confirm_new_password = "";
                    }
                }
                break;
            default:
                break;
        }

        setUserPassword({
            errors: errors,
        });
    }

    const validateForm = () => {
        let valid = true;

        if (txt_user_password == "") {
            errors.txt_user_password = "COMMON.This is a required field";
            valid = false;
        }
        else {
            errors.txt_user_password = "";
        }

        if (txt_user_new_password == "") {
            errors.txt_user_new_password = "COMMON.This is a required field";
            valid = false;
        }
        else {
            errors.txt_user_new_password = "";
        }

        if (txt_user_confirm_new_password == "") {
            errors.txt_user_confirm_new_password = "COMMON.This is a required field";
            valid = false;
        }
        else {
            if (txt_user_new_password != txt_user_confirm_new_password) {
                errors.txt_user_confirm_new_password = "COMMON.New Password And Confirm New Password Must Be Same";
                valid = false;
            }
            else {
                errors.txt_user_confirm_new_password = "";
            }
        }

        setUserPassword({
            errors: errors,
        });

        return valid;
    }

    const handleSave = async () => {
        if (validateForm()) {
            setShowLoading(true);
            setDisableBtnSave(true);

            var title = languages.filter(p => p.languageKey == "COMMON.Change Password")["0"][language];
            var message = "";

            var return_data = await userChangePassword_service(txt_user_password, txt_user_new_password, user_latitude, user_longitude);

            if (return_data == "Success") {
                message = languages.filter(p => p.languageKey == "COMMON.Success")["0"][language];
                toast.success(title, message);
            }
            else {
                message = languages.filter(p => p.languageKey == "COMMON.Error")["0"][language];
                toast.error(title, message);
            }

            setShowLoading(false);
            setDisableBtnSave(false);
        }
    }

    const handleClosePasswordChangeModal = () => {
        props.handleClosePasswordChangeModal();
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as='h5'>
                        {languages.filter(p => p.languageKey == "COMMON.Change Password")["0"][language]}
                        &nbsp;
                        <ReactAccessProvider permissions={enable_language_change_flag}>
                            <RequireForAccess
                                permissions={['true']}
                            >
                                <Link to='#' id="link_chg_lang_risk_indentification" onClick={() => handleShowLanguageModal("COMMON.Change Password")}>
                                    <i className="fa fa-language" aria-hidden="true"></i>
                                </Link>
                            </RequireForAccess>
                        </ReactAccessProvider>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Custom_Textbox
                            id="txt_user_password"
                            name="txt_user_password"
                            type="password"
                            fieldOrder="1"
                            isMandatory={true}
                            label="COMMON.Old Password"
                            isDefault={true}
                            maxLength="50"
                            defaultValue={txt_user_password}
                            onBlur={handleChange}
                        />

                        <Custom_Textbox
                            id="txt_user_new_password"
                            name="txt_user_new_password"
                            type="password"
                            fieldOrder="2"
                            isMandatory={true}
                            label="COMMON.New Password"
                            isDefault={true}
                            maxLength="50"
                            defaultValue={txt_user_new_password}
                            onBlur={handleChange}
                        />

                        <Custom_Textbox
                            id="txt_user_confirm_new_password"
                            name="txt_user_confirm_new_password"
                            type="password"
                            fieldOrder="3"
                            isMandatory={true}
                            label="COMMON.Confirm New Password"
                            isDefault={true}
                            maxLength="50"
                            defaultValue={txt_user_confirm_new_password}
                            onBlur={handleChange}
                        />
                    </Row>

                    {showLoading && <Loading />}
                </Card.Body>
                <Card.Footer className="text-center">
                    <Button id="btn_next" className='important-form-btn float-right margin-left-right-5px' type="button" disabled={disableBtnSave} onClick={() => handleSave()}>
                        {languages.filter(p => p.languageKey == "COMMON.Save")["0"][language]}
                    </Button>

                    <Button id="btn_back" className='important-form-btn float-right margin-left-right-5px' variant='danger' type="button" onClick={() => handleClosePasswordChangeModal()}>
                        {languages.filter(p => p.languageKey == "COMMON.Cancel")["0"][language]}
                    </Button>
                </Card.Footer>
            </Card>

            <AnimatedModal visible={showLanguageModal} onClose={() => setShowLanguageModal(false)} animation="rotate">
                <LanguageChangeModal editLang={selectedLanguage} handleCloseLanguageModal={() => setShowLanguageModal(false)} />
            </AnimatedModal>
        </>
    );
}

export default ChangePasswordModal;
