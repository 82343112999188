import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import { BASE_URL } from "../../config/constant";

const AccessRightsGuard = ({ children }) => {
    const { access_rights } = useAuth();
    const applicationCode = children.props.children.props["applicationCode"];
    const screenCode = children.props.children.props["screenCode"];

    const applicationAccessData = access_rights.filter(x => x.isRead === true).map(p => p.applicationCode);
    const screenAccessData = access_rights.filter(x => x.isRead === true).map(p => p.screenCode);
    
    if ((applicationCode == "" || applicationCode == undefined)
        && (screenCode == "" || screenCode == undefined)) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    else {
        if (applicationAccessData.includes(applicationCode)) {
            if (screenAccessData.includes(screenCode)) {
                return (
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                );
            }
            else {
                return <Redirect to={BASE_URL} />;
            }
        }
        else {
            return <Redirect to={BASE_URL} />;
        }
    }
};

export default AccessRightsGuard;
