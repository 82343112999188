import AuthAxiosInstance from '../axios_config/AuthAxiosInstance';
import url_parameter_encryption from '../url_parameter_encryption/url_parameter_encryption';

//update the new token 
function updateRefreshToken() {
    AuthAxiosInstance({
        method: "post",
        url: "sp_Token/UpdateAuthenticationToken",
        data: {
            accessToken: sessionStorage.getItem("endevo_accessToken"),
            refreshToken: sessionStorage.getItem("endevo_refreshToken")
        }
    }).then(response => {
        var error_page_parameter = "";
        var error_url = process.env.REACT_APP_SUBDIRECTORY + "/404";

        if (response.data == false) {
            error_page_parameter = "errorcode=" + "500" + "&errormessage=" + "Server Error";
            //window.location = url_parameter_encryption(error_url, error_page_parameter);
        }
    });
}

export default updateRefreshToken;