import Dexie from 'dexie';

const access_rights_indexedDB = new Dexie('Endevo_Access_Rights');

// Declare tables, IDs and indexes
access_rights_indexedDB.version(1).stores({
    AccessRights: 'screenAccessRightsID,applicationID,applicationCode,screenID,screenCode,functionID,functionCode,totalRow,rowNumber,createdByName,modifiedByName',
});

export default access_rights_indexedDB;
