import React, { useContext, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from "react-router-dom";
import RequireForAccess, { ReactAccessProvider } from 'react-access';

import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

import { ConfigContext } from "../../../../../contexts/ConfigContext";

import LanguageChangeModal from '../../../../../components/Modal/LanguageChangeModal/LanguageChangeModal';
import AnimatedModal from '../../../../../components/Modal/AnimatedModal';

const NavGroup = ({ layout, group }) => {
    const configContext = useContext(ConfigContext);
    const { enable_language_change_flag } = configContext.state;

    let navItems = '';
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const handleShowLanguageModal = (lang) => {
        setSelectedLanguage(lang);
        setShowLanguageModal(true);
    }

    if (group.children) {
        const groups = group.children;
        navItems = Object.keys(groups).map(item => {
            item = groups[item];
            switch (item.type) {
                case 'collapse':
                    return <NavCollapse key={item.id} collapse={item} type="main" />;
                case 'item':
                    return <NavItem layout={layout} key={item.id} item={item} />;
                default:
                    return false;
            }
        });
    }

    return (
        <React.Fragment>
            <ListGroup.Item as='li' bsPrefix=' ' key={group.id} className="nav-item pcoded-menu-caption">
                <label>
                    {group.title}
                    &nbsp; 
                    <ReactAccessProvider permissions={enable_language_change_flag}>
                        <RequireForAccess
                            permissions={['true']}
                        >
                            <span onClick={() => handleShowLanguageModal(group.languageKey)}>
                                <i className="fa fa-language" aria-hidden="true"></i>
                            </span>
                        </RequireForAccess>
                    </ReactAccessProvider>
                </label>
            </ListGroup.Item>
            {navItems}

            <AnimatedModal visible={showLanguageModal} onClose={() => setShowLanguageModal(false)} animation="rotate">
                <LanguageChangeModal editLang={selectedLanguage} handleCloseLanguageModal={() => setShowLanguageModal(false)} />
            </AnimatedModal>
        </React.Fragment>
    );
};

export default NavGroup;
