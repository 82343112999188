import { ACCOUNT_INITIALISE, LOGIN, LOGOUT, LANGUAGE_CHANGE, LANGUAGE_UPDATE, LANGUAGE_SOURCE, USER_ACCESS_RIGHTS, USER_VALIDATE_LICENSE, USER_BIND_LICENSE_INFO, USER_UPDATE_LICENSE_INFO, SET_TIMEOUT, SET_REMAINING, SET_DISPLAY_IDLE_MODAL } from "./actions";

const accountReducer = (state, action) => {
    switch (action.type) {
        case LOGIN: {
            const { isLoggedIn, user_latitude, user_longitude } = action.payload;

            return {
                ...state,
                isLoggedIn,
                user_latitude,
                user_longitude
            };
        }

        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                language: "languageEN",
                languages: []
            };
        }

        case LANGUAGE_CHANGE: {
            const { language, navigation_list } = action.payload;

            return {
                ...state,
                language,
                navigation_list
            };
        }

        case LANGUAGE_UPDATE: {
            const { languages, navigation_list } = action.payload;

            return {
                ...state,
                languages,
                navigation_list
            };
        }

        case LANGUAGE_SOURCE: {
            const { languages } = action.payload;

            return {
                ...state,
                languages
            };
        }

        case USER_ACCESS_RIGHTS: {
            const { access_rights, navigation_list } = action.payload;

            return {
                ...state,
                access_rights,
                navigation_list
            };
        }
            
        case USER_VALIDATE_LICENSE: {
            const { isShowUpdateLicense } = action.payload;

            return {
                ...state,
                isShowUpdateLicense
            };
        }

        case USER_BIND_LICENSE_INFO: {
            const { licensekey } = action.payload;

            return {
                ...state,
                licensekey
            };
        }

        case USER_UPDATE_LICENSE_INFO: {
            const { licenseError, licenseUnlock } = action.payload;

            return {
                ...state,
                licenseError,
                licenseUnlock
            };
        }
            
        case ACCOUNT_INITIALISE: {
            const { isLoggedIn, language, languages, access_rights, navigation_list, user_latitude, user_longitude } = action.payload;

            return {
                ...state,
                isLoggedIn,
                isInitialised: true,
                language,
                languages,
                access_rights,
                navigation_list,
                user_latitude,
                user_longitude
            };
        }

        default: {
            return { ...state };
        }
    }
};

export default accountReducer;