import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Alert, Form, Card } from 'react-bootstrap';
import useAuth from '../../../hooks/useAuth';
import { updateLanguage_service } from '../../../services/Common/Common';

const LanguageChangeModal = props => {
    const { language, languages, func_language_update, user_latitude, user_longitude } = useAuth();
    const [error, setError] = useState("");
    const [language_us, setLanguageUS] = useState("");
    const [language_my, setLanguageMY] = useState("");
    const [language_cn, setLanguageCN] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const handleChange_language = (e) => {
        const { name, value } = e.target;

        if (name == "txt_language_us") {
            setLanguageUS(value);
        }
        else if (name == "txt_language_my") {
            setLanguageMY(value);
        }
        else if (name == "txt_language_cn") {
            setLanguageCN(value);
        }
    }

    const handleSave = async (e) => {
        selectedLanguage["languageEN"] = language_us;
        selectedLanguage["languageMY"] = language_my;
        selectedLanguage["languageCN"] = language_cn;

        try {
            var return_data = await updateLanguage_service(selectedLanguage.languageID, selectedLanguage.languageKey, language_us, language_my, language_cn, user_latitude, user_longitude);

            func_language_update(selectedLanguage);
            props.handleCloseLanguageModal();
        }
        catch (err) {
        }

    }

    const handleCloseLanguageModal = () => {
        props.handleCloseLanguageModal();
    }

    useEffect(() => {
        var edit_lang = languages.filter(p => p.languageKey == props.editLang)["0"];
        setSelectedLanguage(edit_lang);

        if (edit_lang != null) {
            setLanguageUS(edit_lang["languageEN"]);
            setLanguageMY(edit_lang["languageMY"]);
            setLanguageCN(edit_lang["languageCN"]);
        }

    }, [props.editLang])

    return (
        <Card>
            <Card.Header>
                <Card.Title as='h5'>
                    {languages.filter(p => p.languageKey == "COMMON.Change Language")["0"][language]}
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col sm={12}>
                        {error && (
                            <Alert variant='danger'>
                                {languages.filter(p => p.languageKey == "COMMON.ERROR.Please key in all require data")["0"][language]}
                            </Alert>
                        )}
                    </Col>

                    <Col sm={12}>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                                {languages.filter(p => p.languageKey == "COMMON.English")["0"][language]}
                            </Form.Label>
                            <Col sm={8}>
                                <textarea
                                    id="txt_language_us"
                                    name="txt_language_us"
                                    defaultValue={language_us}
                                    onBlur={handleChange_language}
                                    type="text"
                                    className="form-control"
                                    maxLength={5000}
                                />
                            </Col>
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                                {languages.filter(p => p.languageKey == "COMMON.Malay")["0"][language]}
                            </Form.Label>
                            <Col sm={8}>
                                <textarea
                                    id="txt_language_my"
                                    name="txt_language_my"
                                    defaultValue={language_my}
                                    onBlur={handleChange_language}
                                    type="text"
                                    className="form-control"
                                    maxLength={5000}
                                />
                            </Col>
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                        <Form.Group as={Row}>
                            <Form.Label column sm={4}>
                                {languages.filter(p => p.languageKey == "COMMON.Chinese")["0"][language]}
                            </Form.Label>
                            <Col sm={8}>
                                <textarea
                                    id="txt_language_cn"
                                    name="txt_language_cn"
                                    defaultValue={language_cn}
                                    onBlur={handleChange_language}
                                    type="text"
                                    className="form-control"
                                    maxLength={5000}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="text-center">
                <Button id="btn_lang_modal_save" type="submit"  className='mr-1 important-form-btn float-right margin-left-right-5px' onClick={() => handleSave()}>
                    {languages.filter(p => p.languageKey == "COMMON.Save")["0"][language]}
                </Button>
                <Button id="btn_lang_modal_cancel" type="button"  variant='danger' className='important-form-btn float-right margin-left-right-5px' onClick={() => handleCloseLanguageModal()}>
                    {languages.filter(p => p.languageKey == "COMMON.Cancel")["0"][language]}
                </Button>
            </Card.Footer>
        </Card>
    );
}

export default LanguageChangeModal;