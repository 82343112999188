import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import useAuth from '../../../hooks/useAuth';
import Custom_Form_Label from '../../../components/Common/Custom_Form_Label/Custom_Form_Label';

const Custom_Textbox = props => {
    const { language, languages } = useAuth();

    const handleChange = (e) => {
        if (props.onChange !== undefined) {
            props.onChange(e);
        }
    }

    const handleBlur = (e) => {
        if (props.onBlur !== undefined) {
            props.onBlur(e);
        }
    }

    return (
        <>
            <Col sm={12} className={"custom_form_management_element_order_" + props.fieldOrder}>
                <Form.Group as={Row}>
                    <Custom_Form_Label
                        label={props.label}
                        isMandatory={props.isMandatory}
                    />

                    <Form.Label column sm={12} md={8} className='padding-top-bottom-1px'>
                        {props.isDefault
                            ?
                            <input
                                id={props.id}
                                name={props.name}
                                defaultValue={props.defaultValue}
                                type={props.type !== undefined ? props.type : "text"}
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                readOnly={props.readOnly !== undefined ? props.readOnly : false}
                                maxLength={props.maxLength}
                                placeholder={props.placeholder}
                            />

                            :
                            <input
                                id={props.id}
                                name={props.name}
                                value={props.value}
                                type={props.type !== undefined ? props.type : "text"}
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                readOnly={props.readOnly !== undefined ? props.readOnly : false}
                                maxLength={props.maxLength}
                                placeholder={props.placeholder}
                            />
                        }

                        {
                            (() => {
                                if (props.errors !== undefined) {
                                    if (props.errors.length > 0) {
                                        return  <span className='text-danger'>{languages.filter(p => p.languageKey == props.errors)["0"][language]}</span>
                                    }
                                }
                            })()
                        }
                    </Form.Label>
                </Form.Group>
            </Col>
        </>
    )
};

export default Custom_Textbox;