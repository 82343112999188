import toast from '../error_handler/Toast';

import url_parameter_encryption from '../url_parameter_encryption/url_parameter_encryption';

import Endevo_indexedDB from '../../common/indexedDB/indexedDB';
import access_rights_indexedDB from '../../common/indexedDB/access_rights_indexedDB';

var ErrorMessage = "";

let failedQueue = [];

export const processQueue = async (error, token) => {
    failedQueue.forEach(async prom => {
        if (error) {
            await prom.reject(error);
        } else {
            await prom.resolve(token);
        }
    })
    failedQueue = [];
}

//handle error message 
export const errorMessageHandle = async (error) => {
    // if has response show the error
    if (error.response) {
        var error_page_parameter = "";
        var error_url = process.env.REACT_APP_SUBDIRECTORY + "/404";

        if (error.response.data != '' || error.response.data != undefined) {
            ErrorMessage = error.response.data.message;
            error_page_parameter = "errorcode=" + error.response.status + "&errormessage=" + ErrorMessage + "&referenceID=" + error.response.data.referenceID;

            if (ErrorMessage == "Duplicate Login") {
                sessionStorage.clear();

                Endevo_indexedDB.Language.clear();
                access_rights_indexedDB.AccessRights.clear();
            }

            //window.location = url_parameter_encryption(error_url, error_page_parameter);
            toast.error(ErrorMessage, 'Reference ID:' + error.response.data.referenceID + '</br>' + 'Error Code:' + error.response.status);
        } else {
            //Setup Generic Response Messages
            if (error.response.status === 401) {
                ErrorMessage = 'UnAuthorized'
            } else if (error.response.status === 404) {
                ErrorMessage = 'API Route is Missing or Undefined'
            } else if (error.response.status === 405) {
                ErrorMessage = 'API Route Method Not Allowed'
            } else if (error.response.status === 422) {
                //Validation Message
            } else if (error.response.status >= 500) {
                ErrorMessage = 'Server Error'
            }

            error_page_parameter = "errorcode=" + error.response.status + "&errormessage=" + ErrorMessage;
            //window.location = url_parameter_encryption(error_url, error_page_parameter);
            toast.error(ErrorMessage, 'Error Code:' + error.response.status);
        }
    }
}
