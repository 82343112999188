import React, { useContext, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import { NavLink, Link } from "react-router-dom";
import RequireForAccess, { ReactAccessProvider } from 'react-access';

import NavIcon from "../NavIcon";
import NavBadge from "../NavBadge";

import { ConfigContext } from "../../../../../contexts/ConfigContext";
import * as actionType from "../../../../../store/actions";
import useWindowSize from "../../../../../hooks/useWindowSize";

import LanguageChangeModal from '../../../../../components/Modal/LanguageChangeModal/LanguageChangeModal';
import AnimatedModal from '../../../../../components/Modal/AnimatedModal';

const NavItem = ({ layout, item }) => {
    const windowSize = useWindowSize();
    const configContext = useContext(ConfigContext);
    const { dispatch } = configContext;
    const { enable_language_change_flag } = configContext.state;
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const handleShowLanguageModal = (lang) => {
        setSelectedLanguage(lang);
        setShowLanguageModal(true);
    }

    let itemTitle = item.title;
    if (item.icon) {
        itemTitle = <span className="pcoded-mtext">{item.title}</span>;
    }

    let itemTarget = '';
    if (item.target) {
        itemTarget = '_blank';
    }

    let subContent;
    if (item.external) {
        subContent = (
            <a href={item.url} target='_blank' rel='noopener noreferrer'>
                <NavIcon items={item} />
                {itemTitle}
                <NavBadge items={item} />
                &nbsp;
                <ReactAccessProvider permissions={enable_language_change_flag}>
                    <RequireForAccess
                        permissions={['true']}
                    >
                        <span onClick={() => handleShowLanguageModal(item.languageKey)}>
                            <i className="fa fa-language" aria-hidden="true"></i>
                        </span>
                    </RequireForAccess>
                </ReactAccessProvider>
            </a>
        );
    } else {
        subContent = (
            <NavLink to={item.url} className="nav-link" exact={true} target={itemTarget}>
                <NavIcon items={item} />
                {itemTitle}
                <NavBadge items={item} />
                &nbsp;
                <ReactAccessProvider permissions={enable_language_change_flag}>
                    <RequireForAccess
                        permissions={['true']}
                    >
                        <span onClick={() => handleShowLanguageModal(item.languageKey)}>
                            <i className="fa fa-language" aria-hidden="true"></i>
                        </span>
                    </RequireForAccess>
                </ReactAccessProvider>
            </NavLink>
        );
    }
    let mainContent = '';
    if (layout === 'horizontal') {
        mainContent = (
            <ListGroup.Item as='li' bsPrefix=' ' onClick={() => dispatch({ type: actionType.NAV_CONTENT_LEAVE })}>{subContent}</ListGroup.Item>
        );
    } else {
        if (windowSize.width < 992) {
            mainContent = (
                <ListGroup.Item as='li' bsPrefix=' ' className={item.classes} onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}>{subContent}</ListGroup.Item>
            );
        } else {
            mainContent = (
                <ListGroup.Item as='li' bsPrefix=' ' className={item.classes}>{subContent}</ListGroup.Item>
            );
        }
    }

    return (
        <React.Fragment>
            {mainContent}

            <AnimatedModal visible={showLanguageModal} onClose={() => setShowLanguageModal(false)} animation="rotate">
                <LanguageChangeModal editLang={selectedLanguage} handleCloseLanguageModal={() => setShowLanguageModal(false)} />
            </AnimatedModal>
        </React.Fragment>
    );
};

export default NavItem;
