import axios from 'axios';
import errorResponseHandler from '../error_handler/ErrorHandler';

const CommonNoAuthAxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_COMMON_API_URL,
    timeout: 0,
    headers: {
        'Accept-Version': 1,
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
    }
});

// apply interceptor on response
CommonNoAuthAxiosInstance.interceptors.response.use(
    response => response,
    (error) => {
        errorResponseHandler(error);
    }
);

export default CommonNoAuthAxiosInstance;
