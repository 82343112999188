import React, { useContext, createContext, useEffect, useState, useReducer, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Row, Col, Card, Form, Button, ButtonGroup } from 'react-bootstrap';
import Countdown from 'react-countdown-now';

import useAuth from '../../hooks/useAuth';

import { useIdleTimer } from 'react-idle-timer';
import AnimatedModal from '../../components/Modal/AnimatedModal';
import State_Merge from '../../common/state_merge/state_merge';
import { ConfigContext } from "../../contexts/ConfigContext";

const AuthGuard = ({ children }) => {
    const configContext = useContext(ConfigContext);
    const { idle_time, idle_modal_prompt_time } = configContext.state;

    const { language, languages, isLoggedIn, func_logout } = useAuth();
    const idle_timeOutTimer = idle_time;
    const idle_response_timeOutTimer = idle_modal_prompt_time;
    const [idleInterval, setIdleInterval] = useState(null);

    const [authGuardIdle, setAuthGuardIdle] = State_Merge({
        showIdleModal: false,
        idle_elapsed: 0,
        idle_lastActive: new Date().getTime(),
        idle_startTime: new Date().getTime(),
        idle_startFlag: false,
        idle_isTimedOut: false,
        stop_idle_isTimedOut: false
    });

    const {
        showIdleModal,
        idle_elapsed,
        idle_lastActive,
        idle_startTime,
        idle_startFlag,
        idle_isTimedOut,
        stop_idle_isTimedOut
    } = authGuardIdle;

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            handleIdleLogout();
        } else {
            return <span> {minutes}:{seconds} </span>;
        }
    };

    const handleOnIdle = async () => {
        if (idle_startFlag == false) {
            setAuthGuardIdle({
                showIdleModal: true,
                idle_startTime: new Date().getTime(),
                idle_startFlag: true,
                idle_isTimedOut: true
            });
        }
    };

    const handleCloseIdleModal = () => {
        clearInterval(idleInterval);
        setIdleInterval(null);

        setAuthGuardIdle({
            showIdleModal: false,
            idle_startTime: new Date().getTime(),
            idle_startFlag: false,
            idle_elapsed: getElapsedTime(),
            idle_lastActive: getLastActiveTime()
        });


    }

    const handleIdleLogout = async () => {
        setAuthGuardIdle({
            showIdleModal: false,
            idle_isTimedOut: true,
            stop_idle_isTimedOut: true
        });

        await func_logout();
    }

    const {
        reset,
        pause,
        getRemainingTime,
        getLastActiveTime,
        getElapsedTime
    } = useIdleTimer({
        timeout: idle_timeOutTimer,
        onIdle: handleOnIdle,
        debounce: 500
    })

    if (!isLoggedIn) {
        return <Redirect to="/auth/signin" />;
    }

    return (
        <>
            <React.Fragment>
                {children}
            </React.Fragment>

            <AnimatedModal visible={showIdleModal} onClose={() => handleCloseIdleModal()} animation="rotate">
                <Card>
                    <Card.Header>
                        <Card.Title as='h5'>
                            {languages.length > 0 ? languages.filter(p => p.languageKey == "COMMON.You Have Been Idle")["0"][language] : ""}
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col sm={12}>
                                {languages.length > 0 ? languages.filter(p => p.languageKey == "COMMON.Time Out In")["0"][language] : ""} :
                                {showIdleModal == true &&
                                    <Countdown
                                        date={Date.now() + idle_response_timeOutTimer}
                                        renderer={renderer}
                                    />
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer className="text-center">
                        <Button id="btn_lang_modal_cancel" type="button"  variant='danger' className='important-form-btn float-right' onClick={() => handleCloseIdleModal()}>
                            {languages.length > 0 ? languages.filter(p => p.languageKey == "COMMON.Continue")["0"][language] : ""}
                        </Button>
                        <Button id="btn_lang_modal_save" type="submit"  className='mr-1 important-form-btn float-right' onClick={() => handleIdleLogout()}>
                            {languages.length > 0 ? languages.filter(p => p.languageKey == "COMMON.Logout")["0"][language] : ""}
                        </Button>
                    </Card.Footer>
                </Card>
            </AnimatedModal>
        </>
    );
};

export default AuthGuard;
