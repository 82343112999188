import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { UserProvider } from "./contexts/UserContext";

import routes, { renderRoutes } from "./routes";
import { BASENAME } from "./config/constant";
import './App.scss';

const App = () => {
    return (
        <React.Fragment>
            <Router basename={BASENAME}>
                <UserProvider>
                    {renderRoutes(routes)}
                </UserProvider>
            </Router>
        </React.Fragment>
    );
};

export default App;
