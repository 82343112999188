import React, { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import RequireForAccess, { ReactAccessProvider } from 'react-access';
import { useHistory, Link } from 'react-router-dom';

import useAuth from '../../../hooks/useAuth';
import { ConfigContext } from "../../../contexts/ConfigContext";
import Custom_Require_Symbol from '../../../components/Common/Custom_Require_Symbol/Custom_Require_Symbol';

import LanguageChangeModal from '../../../components/Modal/LanguageChangeModal/LanguageChangeModal';
import AnimatedModal from '../../../components/Modal/AnimatedModal';

const Custom_Form_Label = props => {

    const configContext = useContext(ConfigContext);
    const { enable_language_change_flag } = configContext.state;
    const { language, languages } = useAuth();

    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const handleShowLanguageModal = (lang) => {
        setSelectedLanguage(lang);
        setShowLanguageModal(true);
    }

    return (
        <>
            <Form.Label column sm={12} md={4} className='padding-top-bottom-1px'>
                {languages.filter(p => p.languageKey == props.label)["0"] ? languages.filter(p => p.languageKey == props.label)["0"][language] : props.label}
                {props.isMandatory === 1 &&
                    <Custom_Require_Symbol />
                }
                &nbsp;
                <ReactAccessProvider permissions={enable_language_change_flag}>
                    <RequireForAccess
                        permissions={['true']}
                    >
                        <Link to='#' id="link_chg_lang" onClick={() => handleShowLanguageModal(props.label)}>
                            <i className="fa fa-language" aria-hidden="true"></i>
                        </Link>
                    </RequireForAccess>
                </ReactAccessProvider>
                :
            </Form.Label>

            <AnimatedModal visible={showLanguageModal} onClose={() => setShowLanguageModal(false)} animation="rotate">
                <LanguageChangeModal editLang={selectedLanguage} handleCloseLanguageModal={() => setShowLanguageModal(false)} />
            </AnimatedModal>
        </>
    )
};

export default Custom_Form_Label;