const menuItems = {
    items: [
        {
            id: 'navigation',
            languageKey: 'COMMON.Navigation',
            title: 'Navigation',
            type: 'group',
            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'home',
                    languageKey: 'COMMON.Home',
                    title: 'Home',
                    type: 'collapse',
                    applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                    screenID: '',
                    icon: 'feather icon-home',
                    children: [
                        {
                            id: 'default',
                            languageKey: 'COMMON.Default',
                            title: 'Default',
                            type: 'item',
                            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                            screenID: '5bfb3236-07bb-11ea-a23c-3ca82aa779fb',
                            url: '/app/home/dashboard'
                        }
                    ]
                }
            ]
        },
        {
            id: 'system_admin',
            languageKey: 'COMMON.System Admin',
            title: 'System Admin',
            type: 'group',
            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'system_admin',
                    languageKey: 'COMMON.System Admin',
                    title: 'System Admin',
                    type: 'collapse',
                    applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'user_management',
                            languageKey: 'SYSADMIN.User Management',
                            title: 'User Management',
                            type: 'item',
                            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                            screenID: '5c0b9a5b-07bb-11ea-a23c-3ca82aa779fb',
                            url: '/app/sysadmin/user_management/user_listing'
                        },
                        {
                            id: 'role_management',
                            languageKey: 'SYSADMIN.User Role Management',
                            title: 'User Role Management',
                            type: 'item',
                            applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                            screenID: '182e50a2-c681-4a23-8d76-241ffdf4dfa9',
                            url: '/app/sysadmin/user_management/user_role_listing'
                        }
                    ]
                }
            ]
        },
        {
            id: 'lead_management',
            languageKey: 'COMMON.Lead',
            title: 'Lead',
            type: 'group',
            applicationID: '4fbd7d64-48a2-4ea6-af9d-02e9c37afb07',
            screenID: '',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'lead_management',
                    languageKey: 'COMMON.Lead',
                    title: 'Lead',
                    type: 'collapse',
                    applicationID: '4fbd7d64-48a2-4ea6-af9d-02e9c37afb07',
                    screenID: '',
                    icon: 'feather icon-activity',
                    children: [
                        {
                            id: 'lead_management',
                            languageKey: 'Lead.Lead Management Listing',
                            title: 'Lead Management Listing',
                            type: 'item',
                            applicationID: '4fbd7d64-48a2-4ea6-af9d-02e9c37afb07',
                            screenID: '249c8519-adcb-4d92-9dcc-da5600279780',
                            url: '/app/lead/lead_management/lead_management_listing'
                        }
                    ]
                }
            ]
        }
    ]
};

export default menuItems;
