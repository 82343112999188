import React, { useEffect, useState } from 'react';

const Custom_Require_Symbol = props => {
    return (
        <>
            <span className="padding-left-right-5px" style={{ color: "red" }}>*</span>
        </>
    )
};

export default Custom_Require_Symbol;
