import Dexie from 'dexie';

const Endevo_indexedDB = new Dexie('Endevo');

// Declare tables, IDs and indexes
Endevo_indexedDB.version(1).stores({
    Language: 'languageID, languageKey, languageEN, languageMY, languageCN, statusID, locationIP, latitude, longitude, createdBy, createdDate, modifiedBy, modifiedDate',
});

export default Endevo_indexedDB;
