import axios from 'axios';
import errorResponseHandler from '../error_handler/ErrorHandler';

const AxiosLogin = axios.create({
    baseURL: process.env.REACT_APP_LOGIN_URL,
    timeout: 0
});

// apply interceptor on response
AxiosLogin.interceptors.response.use(
    response => response,
    (error) => {
        errorResponseHandler(error);
    }
);

export default AxiosLogin;
