import React, { createContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import CryptoJS from "crypto-js";

import { ACCOUNT_INITIALISE, LANGUAGE_CHANGE, LANGUAGE_UPDATE, LANGUAGE_SOURCE, USER_ACCESS_RIGHTS, USER_VALIDATE_LICENSE, USER_BIND_LICENSE_INFO, USER_UPDATE_LICENSE_INFO, LOGIN, LOGOUT } from "../store/actions";
import Loader from "../components/Loader/Loader";
import accountReducer from '../store/accountReducer';
import menuItems from '../menu-items';

import Endevo_indexedDB from '../common/indexedDB/indexedDB';
import access_rights_indexedDB from '../common/indexedDB/access_rights_indexedDB';
import get_user_geolocation from '../common/get_user_geolocation/get_user_geolocation';

import { login_service, logout_service, getLanguage_service, getAccessRights_service, validateLicense_service, bindLicense_service, updateLicense_service, refreshToken_service, updateRefreshToken_service } from '../services/Common/Common';

const initialState = {
    isLoggedIn: false,
    isInitialised: false,
    language: "languageEN",
    languages: [],
    access_rights: [],
    navigation_list: menuItems,
    licensekey: "",
    licenseError: "",
    licenseUnlock: "",
    isShowUpdateLicense: false,
    user_latitude: 0.00,
    user_longitude: 0.00
};

const UserContext = createContext({
    ...initialState,
    func_login: () => Promise.resolve(),
    func_logout: () => Promise.resolve(),
    func_language_change: () => Promise.resolve(),
    func_language_update: () => Promise.resolve(),
    func_language_source: () => Promise.resolve(),
    func_user_access_rights: () => Promise.resolve(),
    func_validate_license: () => Promise.resolve(),
    func_bind_license_info: () => Promise.resolve(),
    func_update_license: () => Promise.resolve()
});

export const UserProvider = ({ children }) => {
    const history = useHistory();
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const func_login = async (user_email, user_password) => {
        var result = "Error";
        var return_data = await login_service(user_email, user_password);

        if (return_data != null) {
            var user_location = await get_user_geolocation();

            var user_latitude = user_location.coords.latitude;
            var user_longitude = user_location.coords.longitude;

            var img = "";
            if (return_data.attachmentFilePath === "" || return_data.attachmentFilePath === null) {
                img = process.env.REACT_APP_COMMON_FOLDER_PATH_URL + "default/no-image-available.png";
            }
            else {
                img = return_data.attachmentFilePath;
            }

            if (return_data.librarySettingListing.length > 0) {
                for (let librarySetting of return_data.librarySettingListing) {
                    if (librarySetting.librarySettingName == "Action Plan Name") {
                        window.sessionStorage.setItem("endevo_actionPlanName", librarySetting.isUseLibrary);
                    }
                    else if (librarySetting.librarySettingName == "Target Value") {
                        window.sessionStorage.setItem("endevo_targetValue", librarySetting.isUseLibrary);
                    }
                    else if (librarySetting.librarySettingName == "Trigger Value") {
                        window.sessionStorage.setItem("endevo_triggerValue", librarySetting.isUseLibrary);
                    }
                    else if (librarySetting.librarySettingName == "Incident Reporting Name") {
                        window.sessionStorage.setItem("endevo_incidentReportingName", librarySetting.isUseLibrary);
                    }
                }
            }

            window.sessionStorage.setItem("nav", "user");
            window.sessionStorage.setItem("endevo_userID", return_data.userID);
            window.sessionStorage.setItem("endevo_userName", return_data.userName);
            window.sessionStorage.setItem("endevo_profilePic", img);
            window.sessionStorage.setItem("endevo_LanguageCode", state.language);
            window.sessionStorage.setItem("endevo_userWorkingEmail", return_data.userWorkingEmail);
            window.sessionStorage.setItem("endevo_refreshToken", return_data.refreshToken);
            window.sessionStorage.setItem("endevo_accessToken", return_data.accessToken);
            window.sessionStorage.setItem("endevo_maxTotalFileSize", "750KB");

            if (return_data.userScreenAccessRights.length > 0) {
                var access_rights = return_data.userScreenAccessRights;
                access_rights_indexedDB.AccessRights.clear();
                access_rights_indexedDB.AccessRights.bulkAdd(access_rights);

                var navigation_list = {};

                var new_navData = await func_user_access_rights_format();

                navigation_list = {
                    items: new_navData
                };

                dispatch({
                    type: USER_ACCESS_RIGHTS,
                    payload: {
                        access_rights,
                        navigation_list
                    }
                });
            }

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user_latitude,
                    user_longitude
                }
            });

            result = "Success";
        }
        else {
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: false,
                    user_latitude,
                    user_longitude
                }
            });
        }

        return result;
    };

    const func_logout = async () => {
        await logout_service();
        await func_clear_application_data();
    };

    const func_clear_application_data = async () => {
        sessionStorage.clear();

        await Endevo_indexedDB.Language.clear();
        await access_rights_indexedDB.AccessRights.clear();

        dispatch({
            type: LOGOUT,
            payload: {
                isLoggedIn: false
            }
        });
    };

    const func_language_change = async (language) => {
        window.sessionStorage.setItem('endevo_LanguageCode', language);
        const endevo_accessToken = window.sessionStorage.getItem('endevo_accessToken');

        var navigation = menuItems.items;
        var navigation_list = {};

        if (endevo_accessToken) {
            var access_rights = await access_rights_indexedDB.AccessRights.toArray();

            var applicationAccessData = [...new Set(access_rights.map(p => p.applicationID))];
            var screenAccessData = [...new Set(access_rights.map(p => p.screenID))];

            var new_navData = navigation.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                var navigation_Obj = Obj;
                navigation_Obj.title = state.languages.filter(p => p.languageKey == navigation_Obj.languageKey)["0"][language];

                if (navigation_Obj.hasOwnProperty("children")) {
                    navigation_Obj.children = navigation_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                        var children_Obj = Obj;
                        children_Obj.title = state.languages.filter(p => p.languageKey == children_Obj.languageKey)["0"][language];

                        if (children_Obj.hasOwnProperty("children")) {
                            children_Obj.children = children_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                                var grand_children_Obj = Obj;
                                grand_children_Obj.title = state.languages.filter(p => p.languageKey == grand_children_Obj.languageKey)["0"][language];

                                if (grand_children_Obj.hasOwnProperty("children")) {
                                    grand_children_Obj.children = grand_children_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                                        var great_grand_children_Obj = Obj;
                                        great_grand_children_Obj.title = state.languages.filter(p => p.languageKey == great_grand_children_Obj.languageKey)["0"][language];

                                        if (great_grand_children_Obj.hasOwnProperty("children")) {
                                            great_grand_children_Obj.children = great_grand_children_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == ""));

                                            if (great_grand_children_Obj.children.length > 0) {
                                                return great_grand_children_Obj;
                                            } else {
                                                return null;
                                            }
                                        }
                                        else {
                                            return great_grand_children_Obj;
                                        }
                                    });

                                    if (grand_children_Obj.children.length > 0) {
                                        return grand_children_Obj;
                                    } else {
                                        return null;
                                    }
                                }
                                else {
                                    return grand_children_Obj;
                                }
                            });

                            if (children_Obj.children.length > 0) {
                                return children_Obj;
                            } else {
                                return null;
                            }
                        }
                        else {
                            return children_Obj;
                        }
                    });

                    if (navigation_Obj.children.length > 0) {
                        return navigation_Obj;
                    } else {
                        return null;
                    }
                }
                else {
                    return navigation_Obj;
                }
            });

            navigation_list = {
                items: new_navData
            };
        }
        else {
            navigation_list = menuItems;
        }


        dispatch({
            type: LANGUAGE_CHANGE,
            payload: {
                language,
                navigation_list
            }
        });
    }

    const func_language_update = async (updated_language) => {
        const endevo_accessToken = window.sessionStorage.getItem('endevo_accessToken');
        var languages = state.languages;
        languages.filter(p => p.languageKey == updated_language.languageKey)["0"] = updated_language;

        await Endevo_indexedDB.Language
            .where('languageID')
            .equals(updated_language.languageID,)
            .modify(obj => {
                obj.languageEN = updated_language.languageEN;
                obj.languageMY = updated_language.languageMY;
                obj.languageCN = updated_language.languageCN;
            });

        var navigation = menuItems.items;
        var navigation_list = {};

        if (endevo_accessToken) {
            var access_rights = await access_rights_indexedDB.AccessRights.toArray();

            var applicationAccessData = [...new Set(access_rights.map(p => p.applicationID))];
            var screenAccessData = [...new Set(access_rights.map(p => p.screenID))];

            var new_navData = navigation.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                var navigation_Obj = Obj;
                navigation_Obj.title = state.languages.filter(p => p.languageKey == navigation_Obj.languageKey)["0"][state.language];

                if (navigation_Obj.hasOwnProperty("children")) {
                    navigation_Obj.children = navigation_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                        var children_Obj = Obj;
                        children_Obj.title = state.languages.filter(p => p.languageKey == children_Obj.languageKey)["0"][state.language];

                        if (children_Obj.hasOwnProperty("children")) {
                            children_Obj.children = children_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                                var grand_children_Obj = Obj;
                                grand_children_Obj.title = state.languages.filter(p => p.languageKey == grand_children_Obj.languageKey)["0"][state.language];

                                if (grand_children_Obj.hasOwnProperty("children")) {
                                    grand_children_Obj.children = grand_children_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                                        var great_grand_children_Obj = Obj;
                                        great_grand_children_Obj.title = state.languages.filter(p => p.languageKey == great_grand_children_Obj.languageKey)["0"][state.language];

                                        if (great_grand_children_Obj.hasOwnProperty("children")) {
                                            great_grand_children_Obj.children = great_grand_children_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == ""));

                                            if (great_grand_children_Obj.children.length > 0) {
                                                return great_grand_children_Obj;
                                            } else {
                                                return null;
                                            }
                                        }
                                        else {
                                            return great_grand_children_Obj;
                                        }
                                    });

                                    if (grand_children_Obj.children.length > 0) {
                                        return grand_children_Obj;
                                    } else {
                                        return null;
                                    }
                                }
                                else {
                                    return grand_children_Obj;
                                }
                            });

                            if (children_Obj.children.length > 0) {
                                return children_Obj;
                            } else {
                                return null;
                            }
                        }
                        else {
                            return children_Obj;
                        }
                    });

                    if (navigation_Obj.children.length > 0) {
                        return navigation_Obj;
                    } else {
                        return null;
                    }
                }
                else {
                    return navigation_Obj;
                }
            });

            navigation_list = {
                items: new_navData
            };
        }
        else {
            navigation_list = menuItems;
        }

        dispatch({
            type: LANGUAGE_UPDATE,
            payload: {
                languages,
                navigation_list
            }
        });
    }

    const func_language_source = async () => {
        try {
            var languages = await getLanguage_service();

            dispatch({
                type: LANGUAGE_SOURCE,
                payload: {
                    languages
                }
            });

            await Endevo_indexedDB.Language.clear();
            await Endevo_indexedDB.Language.bulkAdd(languages);
        }
        catch (err) {
            dispatch({
                type: LANGUAGE_SOURCE,
                payload: {
                    languages: []
                }
            });
        }
    };

    const func_user_access_rights_format = async () => {
        var languages = await Endevo_indexedDB.Language.toArray();
        var access_rights = await access_rights_indexedDB.AccessRights.toArray();

        var navigation = menuItems.items;
        var applicationAccessData = [...new Set(access_rights.map(p => p.applicationID))];
        var screenAccessData = [...new Set(access_rights.map(p => p.screenID))];

        var new_navData = navigation.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
            var navigation_Obj = Obj;
            navigation_Obj.title = languages.filter(p => p.languageKey == navigation_Obj.languageKey)["0"][state.language];

            if (navigation_Obj.hasOwnProperty("children")) {
                navigation_Obj.children = navigation_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                    var children_Obj = Obj;
                    children_Obj.title = languages.filter(p => p.languageKey == children_Obj.languageKey)["0"][state.language];

                    if (children_Obj.hasOwnProperty("children")) {
                        children_Obj.children = children_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                            var grand_children_Obj = Obj;
                            grand_children_Obj.title = languages.filter(p => p.languageKey == grand_children_Obj.languageKey)["0"][state.language];

                            if (grand_children_Obj.hasOwnProperty("children")) {
                                grand_children_Obj.children = grand_children_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == "")).filter(function (Obj, key) {
                                    var great_grand_children_Obj = Obj;
                                    great_grand_children_Obj.title = languages.filter(p => p.languageKey == great_grand_children_Obj.languageKey)["0"][state.language];

                                    if (great_grand_children_Obj.hasOwnProperty("children")) {
                                        great_grand_children_Obj.children = great_grand_children_Obj.children.filter((el) => (screenAccessData.includes(el.screenID) || el.screenID == "") && (applicationAccessData.includes(el.applicationID) || el.applicationID == ""));

                                        if (great_grand_children_Obj.children.length > 0) {
                                            return great_grand_children_Obj;
                                        } else {
                                            return null;
                                        }
                                    }
                                    else {
                                        return great_grand_children_Obj;
                                    }
                                });

                                if (grand_children_Obj.children.length > 0) {
                                    return grand_children_Obj;
                                } else {
                                    return null;
                                }
                            }
                            else {
                                return grand_children_Obj;
                            }
                        });

                        if (children_Obj.children.length > 0) {
                            return children_Obj;
                        } else {
                            return null;
                        }
                    }
                    else {
                        return children_Obj;
                    }
                });

                if (navigation_Obj.children.length > 0) {
                    return navigation_Obj;
                } else {
                    return null;
                }
            }
            else {
                return navigation_Obj;
            }
        });

        return new_navData;
    };

    const func_user_access_rights = async () => {
        try {
            var response = await getAccessRights_service();

            if (response != 'undefined' && response != undefined) {
                if (response.data.length > 0) {

                    var access_rights = response.data;
                    access_rights_indexedDB.AccessRights.clear();
                    access_rights_indexedDB.AccessRights.bulkAdd(response.data);

                    var navigation_list = {};

                    var new_navData = await func_user_access_rights_format();

                    navigation_list = {
                        items: new_navData
                    };

                    dispatch({
                        type: USER_ACCESS_RIGHTS,
                        payload: {
                            access_rights,
                            navigation_list
                        }
                    });
                }
                else {
                    access_rights_indexedDB.AccessRights.clear();

                    dispatch({
                        type: USER_ACCESS_RIGHTS,
                        payload: {
                            access_rights: [],
                            navigation_list: menuItems
                        }
                    });
                }
            }
            else {
                access_rights_indexedDB.AccessRights.clear();

                dispatch({
                    type: USER_ACCESS_RIGHTS,
                    payload: {
                        access_rights: [],
                        navigation_list: menuItems
                    }
                });
            }
        }
        catch (err) {
            dispatch({
                type: USER_ACCESS_RIGHTS,
                payload: {
                    access_rights: [],
                    navigation_list: menuItems
                }
            });
        }

    };

    const func_validate_license = async (user_email, user_password) => {
        try {
            var return_data = await validateLicense_service();

            if (return_data != 'undefined') {
                if (return_data.returnCode != 0) {
                    dispatch({
                        type: USER_VALIDATE_LICENSE,
                        payload: {
                            isShowUpdateLicense: true
                        }
                    });

                    func_bind_license_info();
                }
            }
        }
        catch (err) {
            dispatch({
                type: USER_VALIDATE_LICENSE,
                payload: {
                    isShowUpdateLicense: false
                }
            });
        }
    };

    const func_bind_license_info = async () => {
        try {
            var return_data = await bindLicense_service();

            var licensekey = return_data[0].companyLicense;

            dispatch({
                type: USER_BIND_LICENSE_INFO,
                payload: {
                    licensekey
                }
            });
        }
        catch (err) {
            dispatch({
                type: USER_ACCESS_RIGHTS,
                payload: {
                    licensekey: null
                }
            });
        }
    };

    const func_update_license = async (unlockKey) => {
        try {
            var return_data = await updateLicense_service(unlockKey);

            if (return_data != 'undefined') {
                if (return_data.returnCode == 0) {
                    var licenseError = null;
                    var licenseUnlock = state.languages.filter(p => p.languageKey == "COMMON.System License Unlock Successful Message")["0"][state.language];

                    dispatch({
                        type: USER_UPDATE_LICENSE_INFO,
                        payload: {
                            licenseError,
                            licenseUnlock
                        }
                    });
                }
                else {
                    var licenseError = return_data.errorMessage;
                    var licenseUnlock = null;

                    dispatch({
                        type: USER_UPDATE_LICENSE_INFO,
                        payload: {
                            licenseError,
                            licenseUnlock
                        }
                    });
                }
            }
        }
        catch (err) {
            dispatch({
                type: USER_UPDATE_LICENSE_INFO,
                payload: {
                    licenseError: err.message,
                    licenseUnlock: null
                }
            });
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                var endevo_accessToken = window.sessionStorage.getItem('endevo_accessToken');
                var session_language = window.sessionStorage.getItem('endevo_LanguageCode');

                var user_location = await get_user_geolocation();

                var user_latitude = user_location.coords.latitude;
                var user_longitude = user_location.coords.longitude;

                if (window.location.href.includes('?')) {
                    if (window.location.href.includes('ChangeService')) {
                        var encryptquerystring = window.location.href.split('?')[1].split('ChangeService=')[1];

                        var bytes = CryptoJS.AES.decrypt(String(encryptquerystring), process.env.REACT_APP_ENDEVO_SHARE_SECRET_KEY);
                        var url_params = bytes.toString(CryptoJS.enc.Utf8);
                        var params = queryString.parse(url_params);

                        sessionStorage.setItem("nav", "Common");
                        sessionStorage.setItem("endevo_change_services", "true");
                        sessionStorage.setItem("endevo_userID", params.endevo_userID);
                        sessionStorage.setItem("endevo_refreshToken", params.endevo_refreshToken);
                        sessionStorage.setItem("endevo_accessToken", params.endevo_accessToken);
                        sessionStorage.setItem("endevo_LanguageCode", params.endevo_LanguageCode);
                        sessionStorage.setItem("endevo_maxTotalFileSize", params.endevo_maxTotalFileSize);
                        sessionStorage.setItem("endevo_maxFiles", params.endevo_maxFiles);

                        endevo_accessToken = params.endevo_accessToken;
                        session_language = params.endevo_LanguageCode;

                        sessionStorage.setItem("endevo_accessToken", params.endevo_accessToken);
                        sessionStorage.setItem("endevo_LanguageCode", params.endevo_LanguageCode);
                    }
                }

                var languages = await Endevo_indexedDB.Language.toArray();
                var language = "languageEN";

                if (session_language) {
                    language = session_language;
                }
                else {
                    sessionStorage.setItem("endevo_LanguageCode", language);
                }

                if (languages.length == 0) {
                    func_language_source();
                }
                else {
                    dispatch({
                        type: LANGUAGE_SOURCE,
                        payload: {
                            languages
                        }
                    });
                }

                if (endevo_accessToken) {
                    var access_rights = await access_rights_indexedDB.AccessRights.toArray();

                    if (access_rights.length == 0) {
                        func_user_access_rights();
                        var response = await getAccessRights_service();

                        if (response != 'undefined' && response != undefined) {
                            if (response.data.length > 0) {
                                await access_rights_indexedDB.AccessRights.clear();
                                await access_rights_indexedDB.AccessRights.bulkAdd(response.data);
                            }
                        }
                    }

                    var navigation_list = {};

                    var new_navData = await func_user_access_rights_format();

                    navigation_list = {
                        items: new_navData
                    };

                    dispatch({
                        type: ACCOUNT_INITIALISE,
                        payload: {
                            isLoggedIn: true,
                            language,
                            languages,
                            access_rights,
                            navigation_list,
                            user_latitude,
                            user_longitude
                        }
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALISE,
                        payload: {
                            isLoggedIn: false,
                            language,
                            languages,
                            access_rights: [],
                            navigation_list: menuItems,
                            user_latitude: 0.00,
                            user_longitude: 0.00
                        }
                    });
                }
            } catch (err) {
                dispatch({
                    type: ACCOUNT_INITIALISE,
                    payload: {
                        isLoggedIn: false,
                        language: "languageEN",
                        languages: [],
                        access_rights: [],
                        navigation_list: menuItems,
                        user_latitude: 0.00,
                        user_longitude: 0.00
                    }
                });
            }
        };


        init();
    }, []);

    useEffect(() => {
        if (initialState.languages.length < 0) {
            const fetchLanguesData = async () => {
                var languages = await Endevo_indexedDB.Language.toArray();

                if (languages.length == 0) {
                    func_language_source();
                }
                else {
                    dispatch({
                        type: LANGUAGE_SOURCE,
                        payload: {
                            languages
                        }
                    });
                }
            }

            fetchLanguesData();
        }

    }, [initialState.languages]);

    if (!state.isInitialised) {
        return <Loader />;
    }

    return (
        <>
            <UserContext.Provider value={{ ...state, func_login, func_logout, func_language_change, func_language_update, func_language_source, func_user_access_rights, func_validate_license, func_bind_license_info, func_update_license }}>
                {children}
            </UserContext.Provider>
        </>
    );
};

export default UserContext;